@tailwind base;
@tailwind components;
@tailwind utilities;

iframe {
    display: none;
}

body::-webkit-scrollbar,
body::-webkit-scrollbar>* {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body,
body>* {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}